class ServicioObj{
    constructor(diaInicio, horaInicio, diaRealizado, horaRealizado, rutaRealizo, estadoServicio){
        this.diaInicio = diaInicio;
        this.horaInicio = horaInicio;
        this.diaRealizado = diaRealizado;
        this.horaRealizado = horaRealizado;
        this.rutaRealizo = rutaRealizo;
        this.estadoServicio = estadoServicio;
    }
}

class RoedoresEstacionesObj {
    constructor(reporte, cordon, estacion, plaga, cantidadPlaga, cambioMaterial, estado) {
        this.reporte = reporte;
        this.cordon = cordon;
        this.estacion = estacion;
        this.plaga = plaga;
        this.cantidadPlaga = cantidadPlaga;
        this.cambioMaterial = cambioMaterial;
        this.estado = estado;
    }
}

class RoedoresSeguimientoObj {
    constructor(reporte, cordon, quimico, lote, cantidadEquipos, cantidadMaterial, estadoEquipos, tipoAcciones, observacionNota, accionesNota) {
        this.reporte = reporte;
        this.cordon = cordon;
        this.quimico = quimico;
        this.lote = lote;
        this.cantidadEquipos = cantidadEquipos ?? 0;
        this.cantidadMaterial = cantidadMaterial ?? 0;
        this.estadoEquipos = estadoEquipos;
        this.tipoAcciones = tipoAcciones;
        this.observacionNota = observacionNota;
        this.accionesNota = accionesNota;
    }
}

class UvSeguimientoObj{
    constructor(reporte, acciones, observacionNota, accionesNota){
        this.reporte = reporte;
        this.acciones = acciones;
        this.observacionNota = observacionNota;
        this.accionesNota = accionesNota;
    }
}

class UvEquiposObj{
    constructor(reporte, equipo, porcentajeLuzUV, cambioProducto, estadoEquipo){
        this.reporte = reporte;
        this.equipo = equipo;
        this.porcentajeLuzUV = porcentajeLuzUV;
        this.cambioProducto = cambioProducto ?? 0;
        this.estadoEquipo = estadoEquipo ?? 'Sustituir';
        this.uvPlagaEquipo = [];
    }
}

class UvPlagaEquipoObj{
    constructor(uvEquipos, plaga, cantidadPlaga){
        this.uvEquipos = uvEquipos;
        this.plaga = plaga;
        this.cantidadPlaga = cantidadPlaga;
    }
}

class PlagSeguimientoObj{
    constructor(reporte, quimico, lote, concentracion, quimico2, lote2, concentracion2, quimico3, lote3, concentracion3, tipoAcciones, observacionNota, accionesNota){
        this.reporte = reporte;
        this.quimico = quimico;
        this.lote = lote;
        this.concentracion = concentracion;
        this.quimico2 = quimico2;
        this.lote2 = lote2;
        this.concentracion2 = concentracion2;
        this.quimico3 = quimico3;
        this.lote3 = lote3;
        this.concentracion3 = concentracion3;
        this.tipoAcciones = tipoAcciones;
        this.observacionNota = observacionNota;
        this.accionesNota = accionesNota;
    }
}

class PlagRegistroObj{
    constructor(reporte, area, ia, tipoAplicacion, dosificacion, cantidadUtilizada, metrosLinealesTratados){
        this.reporte = reporte;
        this.area = area;
        this.ia = ia;
        this.tipoAplicacion = tipoAplicacion;
        this.dosificacion = dosificacion;
        this.cantidadUtilizada = cantidadUtilizada;
        this.metrosLinealesTratados = metrosLinealesTratados;
    }
}

const param = new URLSearchParams(window.location.search);

const servicioSeg = new ServicioObj();

const roedoresSeguimientos = [new RoedoresSeguimientoObj, new RoedoresSeguimientoObj, new RoedoresSeguimientoObj];
const roedoresEstaciones = [[], [], []];

const uvSeguimiento = new UvSeguimientoObj;
const uvEquipos = [];

const plagSeguimiento = new PlagSeguimientoObj;
const plagRegistros = [];

firma = false;

//Send sign without save in database
function openWindowWithPostRequest(url, data) {
    console.log(url);
    
    // Create a new form element
    var form = document.createElement("form");
    form.target = "_blank";  // This will open the form submission in a new tab
    form.method = "POST";
    form.action = url;  // Set the URL to post to

    // Add data as hidden form fields
    for (var key in data) {
        if (data.hasOwnProperty(key)) {
            var hiddenField = document.createElement("input");
            hiddenField.type = "hidden";
            hiddenField.name = key;
            hiddenField.value = data[key];
            form.appendChild(hiddenField);
        }
    }

    // Append the form to the body (it doesn't need to be visible)
    document.body.appendChild(form);
    console.log(form);

    // Submit the form
    form.submit();

    // Optionally remove the form if you don't want it anymore
    document.body.removeChild(form);
}

document.addEventListener('DOMContentLoaded', function(){
    
    mobileMenu();

    if(window.location.href.includes('/personal/servicios/realizar')){
        iniciarApp();
    }
    
    if(window.location.href.includes('/personal/personal')){
        firmaReporte(false);
    }
    

});

// Menu dinamico
function mobileMenu(){
    const mobileMenu = document.querySelector('.mobile-menu');
    mobileMenu.addEventListener('click', navegacionResponsive);
}
function navegacionResponsive(){
    const barra = document.querySelector('.admin-bar');
    barra.classList.toggle('mostrar');
}

function iniciarApp(){
    consultarInformacionReportesAPI();
    firmaReporte();
    
}

//Firm system

function firmaReporte(reporte = true){
    //Initialize sign
    const canvas = document.querySelector("canvas");
    signaturePad = new SignaturePad(canvas, {
        minWidth: 1,
        maxWidth: 1,
        penColor: "rgb(0, 0, 0)"
    });

    const limpiarFirma = document.querySelector('#limpiarFirma');
    limpiarFirma.addEventListener('click', e=>{
        signaturePad.clear();
    });

    const firmarDocumento = document.querySelector('#firmar');
    firmarDocumento.addEventListener('click', e=>{
        if(!signaturePad.isEmpty()){
            firma = signaturePad.toDataURL();
        }else{
            firma = 'vacio';
        }
        console.log(firma);

        //Decide if is a firm for a report that won't be saved or data that will be saved 
        if(reporte){
            mandarInformacion();
        }else{
            const inputFirma = document.querySelector('#firma');
            if(signaturePad.isEmpty()){
                inputFirma.value = 'vacio';
            }else{
                inputFirma.value = firma;
            }
        }
        
    });
}

async function consultarInformacionReportesAPI(){
    const href = window.location.href;
    const urls = href.split('?');

    try {  
        const url = '/personal/api/datosReporte?'+urls[1];
        const resultado = await fetch(url);
        const informacion = await resultado.json();

        console.log(informacion);
        mostrarInformacion(informacion);
        
    } catch (error) {
        console.log(error);
    }
}

function mostrarInformacion(informacion){
    const {cliente, estacionesNombres, gomasRoedores, personal, quimicoRoedores, quimicoInsecticidas, valoresCalculadosQuimicoInsecticidas, roedoresEstaciones, roedoresSeguimiento, servicio, sucursal, uvNombre} = informacion;
    const formRoedores = document.querySelector('.app__roedores');
    const formUV = document.querySelector('.app__uv');
    const formInsecticidas = document.querySelector('.app__insecticidas');
    
    const diaServicio = new Date(servicio.diaProgramado);

    //Forms control

    if(servicio.tipoServicio =='Periodico' || servicio.tipoServicio=='Correctivo' || servicio.tipoServicio=='Casa Habitacion'){
        if(sucursal.periodo == 'Quincenal'){
            if(diaServicio.getUTCDate() < 16){
                sucursal.plagVisita1 == 0 ? formInsecticidas.remove() : mostrarInsecticidas(valoresCalculadosQuimicoInsecticidas, quimicoInsecticidas);
                sucursal.roeVisita1 == 0 ? formRoedores.remove() : mostrarRoedores(estacionesNombres, quimicoRoedores, gomasRoedores);
                sucursal.uvVisita1 == 0 ? formUV.remove() : mostrarEstacionesUv(uvNombre);
            }else{
                sucursal.plagVisita2 == 0 ? formInsecticidas.remove() : mostrarInsecticidas(valoresCalculadosQuimicoInsecticidas, quimicoInsecticidas);
                sucursal.roeVisita2 == 0 ? formRoedores.remove() : mostrarRoedores(estacionesNombres, quimicoRoedores, gomasRoedores);
                sucursal.uvVisita2 == 0 ? formUV.remove() : mostrarEstacionesUv(uvNombre);
            }
        }else{
            sucursal.plagVisita1 == 0 ? formInsecticidas.remove() : mostrarInsecticidas(valoresCalculadosQuimicoInsecticidas, quimicoInsecticidas);
            sucursal.roeVisita1 == 0 ? formRoedores.remove() : mostrarRoedores(estacionesNombres, quimicoRoedores, gomasRoedores);
            sucursal.uvVisita1 == 0 ? formUV.remove() : mostrarEstacionesUv(uvNombre);
        }
        
    }else if(servicio.tipoServicio=='Instalacion' || servicio.tipoServicio=='Inspeccion' || servicio.tipoServicio=='Especial'){
        formRoedores.remove();
        formUV.remove();
        mostrarInsecticidas(valoresCalculadosQuimicoInsecticidas, quimicoInsecticidas);
    }else{
        formInsecticidas.remove();
        formRoedores.remove();
        formUV.remove();
    };

    informacionServicio();


    const botonEnviar = document.querySelector('#previsualizarReporte');
    botonEnviar.addEventListener('click', e=>{
        firma = null;
        mandarInformacion();
    });

}

//Rodenticide section

function mostrarRoedores(estacionesNombres, quimicoRoedores, gomasRoedores){
    
    const estaciones = [estacionesNombres.filter(element => element.cordon == 1), estacionesNombres.filter(element => element.cordon == 2), estacionesNombres.filter(element => element.cordon == 3)];

    const form = [document.querySelector('#cordon1'), document.querySelector('#cordon2'), document.querySelector('#cordon3')];

    for (let index = 0; index < 3; index++) {
        if(estaciones[index].length === 0){
            form[index].remove();
        }else{
            notasAccionesRoedores(index+1);
            notasObservacionesRoedores(index+1);
            mostrarEstacionesRodenticida(estaciones[index], index+1);
            roedoresSeguimientos[index].cordon = index+1;
            roedoresSeguimientos[index].reporte = param.get('id');
        }
        
    }

    mostrarLotesRodenticidas(quimicoRoedores, gomasRoedores);

}

function buscarLote(arrayQuimicos, idQuimico){
    
    if(idQuimico==null || idQuimico=='NULL' || idQuimico ==''){
        return 'N/A';
    }
    const resultado = arrayQuimicos.find(e=>{
        return e.id == idQuimico;
    });
    return resultado.lote;
}

function buscarQuimico(arrayQuimicos, idQuimico){
    
    if(idQuimico=='' || idQuimico == null){
        return 'N/A';
    }
    const resultado = arrayQuimicos.find(e=>{
        return e.id == idQuimico;
    });
    return resultado;
}

function mostrarLotesRodenticidas(quimicoRoedores, gomasRoedores){
    const quimicoInput = [document.querySelector('#quimicoC1'), document.querySelector('#quimicoC2'),  document.querySelector('#quimicoC3')];

    const loteInput = [document.querySelector('#loteC1'), document.querySelector('#loteC2'), document.querySelector('#loteC3')];

    const idQuimico = [];

    for (let index = 0; index < 3; index++) {
        idQuimico.push(quimicoInput[index]==null ? null : quimicoInput[index].value);
        
    }

    const lote = [buscarLote(quimicoRoedores, idQuimico[0]), buscarLote(quimicoRoedores, idQuimico[1]), buscarLote(gomasRoedores, idQuimico[2])]; 

    for (let index = 0; index < 3; index++) {
        
        if(loteInput[index]==null){
            continue;
        }

        loteInput[index].value = lote[index];
        
        quimicoInput[index].addEventListener('change', e=>{
            const idQuimicoInput = e.target.value;
            const lote = buscarLote(index==2 ? gomasRoedores : quimicoRoedores, idQuimicoInput); 
            loteInput[index].value = lote;
            roedoresSeguimientos[index].lote = loteInput[index].value;
            roedoresSeguimientos[index].quimico = idQuimicoInput;
            console.log(roedoresSeguimientos);
        });
        roedoresSeguimientos[index].lote = loteInput[index].value;
        roedoresSeguimientos[index].quimico = idQuimico[index];
        loteInput[index].addEventListener('change', e=>{
            roedoresSeguimientos[index].lote = e.target.value;
        });
    }
}

function notasAccionesRoedores(numCordon){

    const notasAccionesInput = document.querySelector('#notaAccionesC'+numCordon);
    const opciones = document.querySelectorAll('[name="accionC'+ numCordon +'"]');

    opciones.forEach(opcion=>{
        opcion.addEventListener('click', e=>{
            
            const accion = e.target.value;
            const nota = document.querySelector('[value="'+ accion +'C'+numCordon+'"]');
            
            notasAccionesInput.value = nota.innerHTML;
            roedoresSeguimientos[numCordon-1].tipoAcciones = opcion.value;
            roedoresSeguimientos[numCordon-1].accionesNota = nota.innerHTML;
        });
    });

    notasAccionesInput.addEventListener('change', e=>{
        roedoresSeguimientos[numCordon-1].accionesNota = e.target.value;
    });

}

function notasObservacionesRoedores(numCordon){
    const opcionesObservaciones = document.querySelectorAll('.app__opcion-observacion--C'+numCordon);
    const observacionesInput = document.querySelector('#observacionesC'+numCordon);

    const notas = [];

    opcionesObservaciones.forEach(opcion=>{
        opcion.addEventListener('click', e=>{
            const texto = document.querySelector('#'+e.target.value+" span");
            const temp = notas.indexOf(texto.innerHTML);
            if(temp!=-1){
                notas.splice(temp, 1)
            }else{
                notas.push(texto.innerHTML);
            }

            observacionesInput.value = "Se recomienda ";

            notas.forEach(nota=>{
                observacionesInput.value += nota + ", ";
            });

            roedoresSeguimientos[numCordon-1].observacionNota = observacionesInput.value;     
        });
    });

    observacionesInput.addEventListener('change', e=>{
        roedoresSeguimientos[numCordon-1].observacionNota = e.target.value;
        console.log(roedoresSeguimientos[numCordon-1]);
    });

}

function mostrarEstacionesRodenticida(estacionesNombres, numCordon){
    const dataListEstaciones = document.querySelector('#estacionesC'+numCordon);

    estacionesNombres.forEach(element => {
        const opcion = document.createElement('OPTION');
        opcion.value = element.id;
        opcion.innerHTML = element.nombre;
        opcion.classList.add('opcionEC'+numCordon);
        dataListEstaciones.appendChild(opcion);
    });

    inputEstaciones(estacionesNombres, numCordon);

}

function inputEstaciones(estacionesNombres, numCordon){
    const inputEstaciones = document.querySelector('[name="estacionInicialC'+numCordon+'"]');

    const registrosEstaciones = document.querySelector('#tablaRoedores-registrosC'+numCordon);
    const opcionesEstados = document.querySelector('[name="estado"]');

    inputEstaciones.addEventListener('change', e=>{

        if (estacionesNombres.find(estacion => estacion.id == e.target.value)){

            const nombreEstacion =e.target.options[e.target.selectedIndex].text;
            const idEstacion = e.target.value;

            console.log(e.target.options[e.target.selectedIndex].text);

            roedoresEstaciones[numCordon-1][idEstacion] = new RoedoresEstacionesObj;
            roedoresEstaciones[numCordon-1][idEstacion].reporte = param.get('id');
            roedoresEstaciones[numCordon-1][idEstacion].cordon = numCordon;
            roedoresEstaciones[numCordon-1][idEstacion].cambioMaterial = 0; 

            const registroListaEstaciones = document.querySelector('.opcionEC'+numCordon+'[value="'+ idEstacion +'"]');
            registroListaEstaciones.outerHTML = '';
            
            const registroEstacion = document.createElement('DIV');
            registroEstacion.classList.add('app__registro-estacion');

            const estacion = document.createElement('INPUT');
            estacion.setAttribute('type', 'text');
            estacion.setAttribute('name', nombreEstacion);
            estacion.setAttribute('disabled', '');
            estacion.value = nombreEstacion;
            roedoresEstaciones[numCordon-1][idEstacion].estacion = idEstacion;

            const estado = opcionesEstados.cloneNode(true);
            estado.removeAttribute('disabled');
            estado.addEventListener('change', p=>{
                roedoresEstaciones[numCordon-1][idEstacion].estado = p.target.value;
                roedoresEstaciones[numCordon-1][idEstacion].estado == 'F' ? roedoresEstaciones[numCordon-1][idEstacion].cantidadPlaga=0 : '' ;
            })

            const plaga = document.createElement('INPUT');
            plaga.setAttribute('type', 'text');
            plaga.setAttribute('list', 'plagasRoedores');
            plaga.setAttribute('name', 'plaga');
            plaga.setAttribute('disabled', '');
            plaga.addEventListener('change', p=>{
                roedoresEstaciones[numCordon-1][idEstacion].plaga = p.target.value;
            })

            const cantidadPlaga = document.createElement('INPUT');
            cantidadPlaga.setAttribute('type', 'number');
            cantidadPlaga.setAttribute('min', '0');
            cantidadPlaga.setAttribute('name', 'cantidadPlaga');
            cantidadPlaga.setAttribute('disabled', '');
            cantidadPlaga.addEventListener('change', p=>{
                roedoresEstaciones[numCordon-1][idEstacion].cantidadPlaga = p.target.value;
            })

            const cambioMaterial = document.createElement('INPUT');
            cambioMaterial.setAttribute('type', 'number');
            cambioMaterial.setAttribute('min', '0');
            cambioMaterial.setAttribute('name', 'cambioMaterial');
            cambioMaterial.setAttribute('disabled', '');
            cambioMaterial.addEventListener('change', p=>{
                roedoresEstaciones[numCordon-1][idEstacion].cambioMaterial = p.target.value;
            })

            registroEstacion.appendChild(estacion);
            registroEstacion.appendChild(estado);
            registroEstacion.appendChild(plaga);
            registroEstacion.appendChild(cantidadPlaga);
            registroEstacion.appendChild(cambioMaterial);

            registrosEstaciones.appendChild(registroEstacion);

            estado.focus();

            estado.addEventListener('change', e=>{
                
                switch(e.target.value){
                    case 'F':
                        plaga.removeAttribute('disabled');
                        cantidadPlaga.removeAttribute('disabled');
                        cambioMaterial.removeAttribute('disabled');
                        registroEstacion.removeAttribute('disabled');
                        break;
                    case 'S':
                        plaga.removeAttribute('disabled');
                        cantidadPlaga.removeAttribute('disabled');
                        cambioMaterial.removeAttribute('disabled');
                        registroEstacion.removeAttribute('disabled');
                        break;
                    case 'B':
                        plaga.setAttribute('disabled', '');
                        cantidadPlaga.setAttribute('disabled', '');
                        cambioMaterial.setAttribute('disabled', '');
                        registroEstacion.setAttribute('disabled', '');
                        plaga.value="";
                        cantidadPlaga.value="";
                        cambioMaterial.value="";
                        registroEstacion.value="";
                    case 'RC':
                        plaga.setAttribute('disabled', '');
                        cantidadPlaga.setAttribute('disabled', '');
                        cambioMaterial.setAttribute('disabled', '');
                        registroEstacion.setAttribute('disabled', '');
                        plaga.value="";
                        cantidadPlaga.value="";
                        cambioMaterial.value="";
                        registroEstacion.value="";
                    default:
                        plaga.setAttribute('disabled', '');
                        cantidadPlaga.setAttribute('disabled', '');
                        cambioMaterial.setAttribute('disabled', '');
                        registroEstacion.setAttribute('disabled', '');
                        plaga.value="";
                        cantidadPlaga.value="";
                        cambioMaterial.value="";
                        registroEstacion.value="";
                }
            });



            console.log(roedoresEstaciones);
            console.log(roedoresSeguimientos);

            e.target.value = "";
        }
    });
}

//UV section

function mostrarEstacionesUv(uvNombre){

    uvSeguimiento.reporte = param.get('id');
    
    notasAccionesUV();
    notasObservacionesUV();

    const equipoUv = document.querySelector('#equipo-uv-inicial');
    const equiposUv = document.querySelector('#equipos-uv');

    uvNombre.forEach(element=>{
        const idUV = element.id;

        uvEquipos[idUV.toString()] = new UvEquiposObj;
        uvEquipos[idUV].reporte = param.get('id');
        uvEquipos[idUV].equipo = idUV;

        const newEquipoUV = equipoUv.cloneNode(true);

        const inputPlagaInicial = newEquipoUV.querySelector('.app__input-inicial-uv-insecto');

        newEquipoUV.querySelector('.app__subtitulo').innerHTML = element.nombre;   

        const registrosPlagasUV = newEquipoUV.querySelector('.app__registros-uv');
        const inputPorcentajeLuzUV = newEquipoUV.querySelector('.app__input-porcentaje-uv');
        const inputCambioProducto = newEquipoUV.querySelector('.app__input-cambio-uv');
        const inputEstadoEquipo = newEquipoUV.querySelector('.app__input-funcional-uv');

        uvEquipos[idUV].porcentajeLuzUV = inputPorcentajeLuzUV.value;
        inputPorcentajeLuzUV.addEventListener('change', e=>{ 
            uvEquipos[idUV].porcentajeLuzUV = e.target.value;
            console.log(uvEquipos); 
        })
        inputCambioProducto.addEventListener('change', e=>{
            uvEquipos[idUV].cambioProducto = e.target.checked ? e.target.value : 0;
            console.log(uvEquipos); 
        })
        inputEstadoEquipo.addEventListener('change', e=>{
            uvEquipos[idUV].estadoEquipo = e.target.checked ? e.target.value : 0;
            console.log(uvEquipos); 
        })

       

        inputPlagaInicial.addEventListener('change', e=>{
            const plaga = e.target.value;
            uvEquipos[idUV].uvPlagaEquipo[plaga] = new UvPlagaEquipoObj;
            uvEquipos[idUV].uvPlagaEquipo[plaga].plaga = plaga;

            console.log(uvEquipos);
            const registroListaPlagas = newEquipoUV.querySelector('[value="'+e.target.value+'"]');
            registroListaPlagas ? registroListaPlagas.remove() : '';
            
            const divRegistro = document.createElement('DIV');
            divRegistro.classList.add('app__registro-uv');

            const inputInsecto = document.createElement('INPUT');
            inputInsecto.setAttribute('type', 'text');
            inputInsecto.setAttribute('value', plaga);
            inputInsecto.setAttribute('disabled', '');
            
            const inputCantidadPlaga = document.createElement('INPUT');
            inputCantidadPlaga.setAttribute('type', 'number');
            inputCantidadPlaga.setAttribute('min', 0);

            inputCantidadPlaga.addEventListener('change', e=>{
                uvEquipos[idUV].uvPlagaEquipo[plaga].cantidadPlaga = e.target.value;
                console.log(uvEquipos);
            })
    
            divRegistro.appendChild(inputInsecto);
            divRegistro.appendChild(inputCantidadPlaga);

            registrosPlagasUV.appendChild(divRegistro);

            inputCantidadPlaga.focus();

            inputPlagaInicial.value = '';

            
        })

        equiposUv.appendChild(newEquipoUV);

    });

    equipoUv.remove();
}

function notasAccionesUV(){
    
    const notasAccionesUVInput = document.querySelector('[name="notaAccionesUV"]');
    const opcionesInsecticidas = document.querySelectorAll('[name="accionUV"]');

    opcionesInsecticidas.forEach(opcion=>{
        opcion.addEventListener('click', e=>{
            
            const accion = e.target.value;
            const nota = document.querySelector('[value="'+ accion +'UV"]');
            
            notasAccionesUVInput.value = nota.innerHTML;
            uvSeguimiento.accionesNota = notasAccionesUVInput.value;
            uvSeguimiento.acciones = accion;
        });
    });

    notasAccionesUVInput.addEventListener('change', e=>{
        uvSeguimiento.accionesNota = notasAccionesUVInput.value;
    });
}

function notasObservacionesUV(){
    const opcionesObservaciones = document.querySelectorAll('.app__opcion-observacion--UV');
    const observacionesInput = document.querySelector('#observacionesUV');

    const notas = [];

    opcionesObservaciones.forEach(opcion=>{
        opcion.addEventListener('click', e=>{
            const texto = document.querySelector('#'+e.target.value+" span");
            const temp = notas.indexOf(texto.innerHTML);
            if(temp!=-1){
                notas.splice(temp, 1)
            }else{
                notas.push(texto.innerHTML);
            }

            observacionesInput.value = "Se recomienda ";

            notas.forEach(nota=>{
                observacionesInput.value += nota + ", ";
            });

            uvSeguimiento.observacionNota = observacionesInput.value;
            console.log(uvSeguimiento);
        });
    });

    observacionesInput.addEventListener('change', e=>{
        uvSeguimiento.observacionNota = observacionesInput.value;
        console.log(uvSeguimiento);
    });

}

//Pesticide section

function mostrarInsecticidas(valoresCalculadosQuimicoInsecticidas, quimicoInsecticidas){
    plagSeguimiento.reporte = param.get('id');


    notasAccionesInsecticidas(valoresCalculadosQuimicoInsecticidas, quimicoInsecticidas);
    notasObservacionesInsecticidas();
    areasInsecticidas(valoresCalculadosQuimicoInsecticidas, quimicoInsecticidas);
    quimicosInsecticidas(quimicoInsecticidas, valoresCalculadosQuimicoInsecticidas);
    
}

function notasAccionesInsecticidas(valoresCalculadosQuimicoInsecticidas, quimicoInsecticidas){

    const notasAccionesInsecticidasInput = document.querySelector('#notaAccionesInsecticidas');
    const opcionesInsecticidas = document.querySelectorAll('[name="accionInsecticidas"]');

    const quimicos = document.querySelector('.app__quimicos-insecticidas');
    const areasATratar = document.querySelector('.app__areas-a-tratar');
    const calculoQuimicos = document.querySelector('.app__calculo-quimico');
    quimicos.classList.add('hidden');
    areasATratar.classList.add('hidden');
    calculoQuimicos.classList.add('hidden');

    const inputQuimicoA1 = document.querySelector('#quimicoA1');
    plagSeguimiento.quimico = inputQuimicoA1.value;
    
    opcionesInsecticidas.forEach(opcion=>{
        opcion.addEventListener('click', e=>{
            
            const accion = e.target.value;
            const nota = document.querySelector('[value="'+ accion +'Insecticidas"]');
            
            notasAccionesInsecticidasInput.value = nota.innerHTML;
            plagSeguimiento.accionesNota = notasAccionesInsecticidasInput.value;
            plagSeguimiento.tipoAcciones = accion;

            quimicos.classList.remove('hidden');
            areasATratar.classList.remove('hidden');
            calculoQuimicos.classList.remove('hidden');

            plagSeguimiento.concentracion = plagSeguimiento.tipoAcciones == "correctivas" ? valoresCalculadosQuimicoInsecticidas[plagSeguimiento.quimico]['concentracionCorrectiva'] : valoresCalculadosQuimicoInsecticidas[plagSeguimiento.quimico]['concentracionPreventiva'];
            calculoQuimico(valoresCalculadosQuimicoInsecticidas, quimicoInsecticidas);
            
        });
    });

    notasAccionesInsecticidasInput.addEventListener('change', e=>{
        plagSeguimiento.accionesNota = notasAccionesInsecticidasInput.value;
    });
}

function quimicosInsecticidas(quimicoInsecticidas, valoresCalculadosQuimicoInsecticidas){
    
    const quimicoInsecticida1 = document.querySelector('#quimicoInsecticida1');
    const quimicoInsecticida2 = document.querySelector('#quimicoInsecticida2');
    const quimicoInsecticida3 = document.querySelector('#quimicoInsecticida3');

    quimicoInsecticida3.classList.add('hidden');

    const inputQuimicoA1 = quimicoInsecticida1.querySelector('#quimicoA1');
    const inputQuimicoA2 = quimicoInsecticida2.querySelector('#quimicoA2');
    const inputQuimicoA3 = quimicoInsecticida3.querySelector('#quimicoA3');
    
    const inputLoteA1 = quimicoInsecticida1.querySelector('#loteA1');
    const inputLoteA2 = quimicoInsecticida2.querySelector('#loteA2');
    const inputLoteA3 = quimicoInsecticida3.querySelector('#loteA3');

    inputLoteA1.value = buscarLote(quimicoInsecticidas, inputQuimicoA1.value);
    plagSeguimiento.lote = inputLoteA1.value;

    inputQuimicoA1.addEventListener('change', e=>{
        inputLoteA1.value = buscarLote(quimicoInsecticidas, e.target.value);
        plagSeguimiento.quimico = e.target.value;
        plagSeguimiento.lote = inputLoteA1.value;
        plagSeguimiento.concentracion = plagSeguimiento.tipoAcciones == 'correctivas' ?  valoresCalculadosQuimicoInsecticidas[e.target.value]['concentracionCorrectiva'] : valoresCalculadosQuimicoInsecticidas[e.target.value]['concentracionPreventiva'];
        calculoQuimico(valoresCalculadosQuimicoInsecticidas, quimicoInsecticidas);
    });
    inputQuimicoA2.addEventListener('change', e=>{
        inputLoteA2.value = buscarLote(quimicoInsecticidas, e.target.value);
        plagSeguimiento.quimico2 = e.target.value;
        plagSeguimiento.lote2 = inputLoteA2.value;
        plagSeguimiento.concentracion2 = plagSeguimiento.tipoAcciones == 'correctivas' ?  valoresCalculadosQuimicoInsecticidas[e.target.value]['concentracionCorrectiva'] : valoresCalculadosQuimicoInsecticidas[e.target.value]['concentracionPreventiva'];
        quimicoInsecticida3.classList.remove('hidden');
        calculoQuimico(valoresCalculadosQuimicoInsecticidas, quimicoInsecticidas);
    });
    inputQuimicoA3.addEventListener('change', e=>{
        inputLoteA3.value = buscarLote(quimicoInsecticidas, e.target.value);
        plagSeguimiento.quimico3 = e.target.value;
        plagSeguimiento.lote3 = inputLoteA3.value;
        plagSeguimiento.concentracion3 = plagSeguimiento.tipoAcciones == 'correctivas' ?  valoresCalculadosQuimicoInsecticidas[e.target.value]['concentracionCorrectiva'] : valoresCalculadosQuimicoInsecticidas[e.target.value]['concentracionPreventiva'];
        calculoQuimico(valoresCalculadosQuimicoInsecticidas, quimicoInsecticidas);
    });

    inputLoteA1.addEventListener('change', e=>{
        plagSeguimiento.lote = e.target.value;
        console.log(plagSeguimiento);
    });
    inputLoteA2.addEventListener('change', e=>{
        plagSeguimiento.lote2 = e.target.value;
        console.log(plagSeguimiento);
    });
    inputLoteA3.addEventListener('change', e=>{
        plagSeguimiento.lote3 = e.target.value;
        console.log(plagSeguimiento);
    });
    
}

function notasObservacionesInsecticidas(){
    const opcionesObservaciones = document.querySelectorAll('.app__opcion-observacion--insecticidas');
    const observacionesInput = document.querySelector('#observacionesInsecticidas');

    const notas = [];

    opcionesObservaciones.forEach(opcion=>{
        opcion.addEventListener('click', e=>{
            const texto = document.querySelector('#'+e.target.value+" span");
            const temp = notas.indexOf(texto.innerHTML);
            if(temp!=-1){
                notas.splice(temp, 1)
            }else{
                notas.push(texto.innerHTML);
            }

            observacionesInput.value = "Se recomienda ";

            notas.forEach(nota=>{
                observacionesInput.value += nota + ", ";
            });

            plagSeguimiento.observacionNota = observacionesInput.value;
            console.log(plagSeguimiento);
        });
    });

    observacionesInput.addEventListener('change', e=>{
        plagSeguimiento.observacionNota = observacionesInput.value;
        console.log(plagSeguimiento);
    });

}

function calculoQuimico(valoresCalculadosQuimicoInsecticidas, quimicoInsecticidas){
    const registrosCalculoQuimicos = document.querySelectorAll('.app__registro-quimico-area');
    const inputMetrosLineales = document.querySelector('#metrosLineales');
    const inputMililitrosMezcla = document.querySelector('#mililitrosMezcla');
    inputMililitrosMezcla.value = 0;

    const quimico = [buscarQuimico(quimicoInsecticidas, plagSeguimiento.quimico), buscarQuimico(quimicoInsecticidas, plagSeguimiento.quimico2), buscarQuimico(quimicoInsecticidas, plagSeguimiento.quimico3)];

    registrosCalculoQuimicos.forEach(elemento=>{
        const idArea = elemento.querySelector('[type="hidden"]');
        const indiceQuimico = document.querySelector('#indiceQuimico-'+idArea.value); 
    
        const tamArea = elemento.querySelector('.app__registro-quimico-tamano');
        const calculoInput = elemento.querySelector('.app__registro-quimico-calculado');

        plagRegistros[idArea.value].metrosLinealesTratados = tamArea.value;

        switch(indiceQuimico.value){
            case '1A':
                calculoInput.value = (tamArea.value*valoresCalculadosQuimicoInsecticidas[plagSeguimiento.quimico]['mililitrosMezclaPorMetro']).toFixed(2);
                if(plagSeguimiento.tipoAcciones=='correctivas'){
                    console.log(quimico[0]);
                    plagRegistros[idArea.value].dosificacion = quimico[0].dosificacionMax;
                }else{
                    plagRegistros[idArea.value].dosificacion = quimico[0].dosificacionMin;
                }
                break;
            case '2A':
                calculoInput.value = plagSeguimiento.quimico2==null ? 0 : (tamArea.value*valoresCalculadosQuimicoInsecticidas[plagSeguimiento.quimico2]['mililitrosMezclaPorMetro']).toFixed(2);
                if(plagSeguimiento.tipoAcciones=='correctivas'){
                    plagRegistros[idArea.value].dosificacion = plagSeguimiento.quimico2==null ? 0 : quimico[1].dosificacionMax;
                }else{
                    plagRegistros[idArea.value].dosificacion = plagSeguimiento.quimico2==null ? 0 : quimico[1].dosificacionMin;
                }
                break;
            case '3A':
                calculoInput.value = plagSeguimiento.quimico3==null ? 0 : (tamArea.value*valoresCalculadosQuimicoInsecticidas[plagSeguimiento.quimico3]['mililitrosMezclaPorMetro']).toFixed(2);
                if(plagSeguimiento.tipoAcciones=='correctivas'){
                    plagRegistros[idArea.value].dosificacion = plagSeguimiento.quimico3==null ? 0 : quimico[2].dosificacionMax;
                }else{
                    plagRegistros[idArea.value].dosificacion = plagSeguimiento.quimico3==null ? 0 : quimico[2].dosificacionMin;
                }
                break;
            default:
                calculoInput.value = 0;
                plagRegistros[idArea.value].dosificacion = 'NULL'
                break;
            
        }
        plagRegistros[idArea.value].cantidadUtilizada = calculoInput.value;

        inputMililitrosMezcla.value = Number(inputMililitrosMezcla.value) + Number(calculoInput.value);

    });

    inputMililitrosMezcla.addEventListener('change', e=>{
        inputMetrosLineales.value = (e.target.value * valoresCalculadosQuimicoInsecticidas[plagSeguimiento.quimico]['metrosLineales'] / 1000).toFixed(2);
        if(registrosCalculoQuimicos.length == 0){
            plagRegistros[Object.keys(plagRegistros)[0]].metrosLinealesTratados = inputMetrosLineales.value;
            plagRegistros[Object.keys(plagRegistros)[0]].cantidadUtilizada = e.target.value;
            if(plagSeguimiento.tipoAcciones=='correctivas'){
                plagRegistros[Object.keys(plagRegistros)[0]].dosificacion = quimico[0].dosificacionMax;
            }else{
                plagRegistros[Object.keys(plagRegistros)[0]].dosificacion = quimico[0].dosificacionMin;
            }
            console.log(plagRegistros);
        }
    });
    inputMetrosLineales.addEventListener('change', e=>{
        inputMililitrosMezcla.value = (e.target.value * valoresCalculadosQuimicoInsecticidas[plagSeguimiento.quimico]['mililitrosMezclaPorMetro']).toFixed(2);
        if(registrosCalculoQuimicos.length == 0){
            plagRegistros[Object.keys(plagRegistros)[0]].metrosLinealesTratados = e.target.value;
            plagRegistros[Object.keys(plagRegistros)[0]].cantidadUtilizada = inputMililitrosMezcla.value;
            if(plagSeguimiento.tipoAcciones=='correctivas'){
                plagRegistros[Object.keys(plagRegistros)[0]].dosificacion = quimico[0].dosificacionMax;
            }else{
                plagRegistros[Object.keys(plagRegistros)[0]].dosificacion = quimico[0].dosificacionMin;
            }
            console.log(plagRegistros);
        }
    });
    console.log(plagRegistros);
}

function areasInsecticidas(valoresCalculadosQuimicoInsecticidas, quimicoInsecticidas){
    const registrosAreas = document.querySelectorAll('.app__registro-area');
    registrosAreas.forEach(registro=>{
        const idArea = registro.querySelector('[type="hidden"]');
        const inputIndiceQuimico = registro.querySelector('.app__indice-quimico-registro');
        const inputTipoAplicacion = registro.querySelector('.app__tipo-aplicacion-registro');

        plagRegistros[idArea.value] = new PlagRegistroObj();
        plagRegistros[idArea.value].reporte = param.get('id');
        plagRegistros[idArea.value].area = idArea.value;
        plagRegistros[idArea.value].ia = inputIndiceQuimico.value;
        plagRegistros[idArea.value].tipoAplicacion = inputTipoAplicacion.value;

        inputIndiceQuimico.addEventListener('change', e=>{
            plagRegistros[idArea.value].ia = e.target.value;
            calculoQuimico(valoresCalculadosQuimicoInsecticidas, quimicoInsecticidas);
            console.log(plagRegistros);
        });

        inputTipoAplicacion.addEventListener('change', e=>{
            plagRegistros[idArea.value].tipoAplicacion = e.target.value;
            console.log(plagRegistros);
        })
        
    });
    
}

// Prepare and send data

function informacionServicio(){
    const infoServicio = {
        'id': document.querySelector('#idServicio'),
        'diaInicio': document.querySelector('#diaInicio'),
        'horaInicio': document.querySelector('#horaInicio'),
        'diaRealizado': document.querySelector('#diaRealizado'),
        'horaRealizado': document.querySelector('#horaRealizado'),
        'rutaRealizo': document.querySelector('#rutaRealizo'),
        'estadoServicio': document.querySelector('#estadoServicio'),
        'nombreRecibe': document.querySelector('#nombreRecibe'),
        'correoEnvio': document.querySelector('#correoEnvio')
    };
    Object.keys(infoServicio).forEach(key=>{
        servicioSeg[key] = infoServicio[key].value;
        infoServicio[key].addEventListener('change', e=>{
            servicioSeg[key] = e.target.value;
            console.log(servicioSeg);
        });
        
    });
    console.log(servicioSeg);
}

async function mandarInformacion(){

    const formRoedores = document.querySelector('.app__roedores');
    const formUV = document.querySelector('.app__uv');
    const formInsecticidas = document.querySelector('.app__insecticidas');

    informacionServicio();

    const datos = new FormData();
    Object.keys(servicioSeg).forEach(key=>{
        datos.append('servicio['+key+']', servicioSeg[key]);
    });

    if(formRoedores){
        Object.keys(roedoresSeguimientos).forEach(key=>{
            Object.keys(roedoresSeguimientos[key]).forEach(key2=>{
                datos.append('roedoresSeguimientos['+key+']['+key2+']', roedoresSeguimientos[key][key2]);
            })
        });
        Object.keys(roedoresEstaciones).forEach(key=>{
            Object.keys(roedoresEstaciones[key]).forEach(key2=>{
                Object.keys(roedoresEstaciones[key][key2]).forEach(key3=>{
                    datos.append('roedoresEstaciones['+key+']['+key2+']['+key3+']', roedoresEstaciones[key][key2][key3]);
                })
            })
        });
    }
    if(formUV){
        Object.keys(uvSeguimiento).forEach(key=>{
            datos.append('uvSeguimiento['+key+']', uvSeguimiento[key]);
        });
        Object.keys(uvEquipos).forEach(key=>{
            Object.keys(uvEquipos[key]).forEach(key2=>{
                if(key2 == 'uvPlagaEquipo'){
                    Object.keys(uvEquipos[key][key2]).forEach(key3=>{
                        Object.keys(uvEquipos[key][key2][key3]).forEach(key4=>{
                            datos.append('uvEquipos['+key+']['+key2+']['+key3+']['+key4+']', uvEquipos[key][key2][key3][key4]);
                        })
                    })
                }else{
                    datos.append('uvEquipos['+key+']['+key2+']', uvEquipos[key][key2]);
                }
            })
        });
    }
    if(formInsecticidas){
        Object.keys(plagSeguimiento).forEach(key=>{
            datos.append('plagSeguimiento['+key+']', plagSeguimiento[key]);
        });
    
        Object.keys(plagRegistros).forEach(key=>{
            Object.keys(plagRegistros[key]).forEach(key2=>{
                datos.append('plagRegistros['+key+']['+key2+']', plagRegistros[key][key2]);
            })
        });
    }    

    try {
        const url = '/personal/api/llenadoReporte';
        const respuesta = await fetch(url, {
            method: 'POST',
            body: datos
        });
        const resultado = await respuesta.json();
        if(resultado[0]=='si'){
            Swal.fire({
                title: "Bien!",
                text: "Se han almacenado los datos",
                icon: "success"
            }).then(()=>{
                var sign={
                    firma: firma
                }
                openWindowWithPostRequest(window.location.origin+'/personal/reporte?id='+param.get('id'), sign);
                // if(firma!=false){
                //     window.location.href = '/personal/servicios/servicio?id='+param.get('id')+'&temp=0';
                // }
            });
        }else{
            Swal.fire({
                icon: "error",
                title: "Oops...",
                text: resultado[1],
                footer: ''
            });
        }
        
        console.log(resultado);
        
    } catch (error) {
        Swal.fire({
            icon: "error",
            title: "Oops...",
            text: error,
            footer: ''
        });
        console.log(error);
    }
}


